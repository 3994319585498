import {Route, Switch} from "react-router-dom"
import VenueProvider from "./components/VenueProvider"
import HomePage from "./pages/HomePage"
import {LeadDetailPage} from "./pages/LeadPage"
import LeadsPage from "./pages/LeadsPage"

export type PageName = keyof typeof AppRoutes.pages
export class AppRoutes {
  static pages = {
    Home: "/",
    LeadPage: "/leads/:eventId",
    LeadEventProfilePage: "/leads/:eventId/event-profile",
    LeadProposalPage: "/leads/:eventId/proposal",
    LeadConcessionPage: "/leads/:eventId/concession",
    LeadsPage: "/leads",
  }
  static getPath(
    name: PageName,
    pathParams: {[key: string]: string} = {},
    queryParams: {[key: string]: string} = {}
  ): string {
    let path = this.pages[name]
    Object.keys(pathParams).forEach((key) => {
      let value = pathParams[key]
      let toReplace = ":" + key
      path = path.replace(toReplace, value)
    })
    if (Object.keys(queryParams).length > 0) {
      path += "?"
    }
    let queryString = new URLSearchParams(queryParams).toString()
    path += queryString

    return path
  }
}

export default function Stack() {
  return (
    <Switch>
      <VenueProvider>
        <Route exact path={AppRoutes.getPath("Home")}>
          <HomePage />
        </Route>
        <Route exact path={AppRoutes.getPath("LeadsPage")}>
          <LeadsPage />
        </Route>
        <Route exact path={AppRoutes.getPath("LeadPage")}>
          <LeadDetailPage />
        </Route>
      </VenueProvider>
    </Switch>
  )
}
