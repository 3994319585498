import {PersonIcon, TriangleDownIcon} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Button} from "@summtech/flok-base/components/Button"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getEvent, getEventProfile} from "@summtech/flok-core/api/event"
import {getUser, getUserHome} from "@summtech/flok-core/api/user"
import {getRFPSteps} from "@summtech/flok-core/api/venue"
import {Divider} from "@summtech/flok-core/components/common"
import KanbanGroupDisplay from "@summtech/flok-core/components/layout/KanbanGroupDisplay"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import AppReactTable from "@summtech/flok-core/components/tables/AppReactTable"
import {
  RFPStatus,
  RFPStepModel,
  RFPStepType,
  RFPStepTypeToTextForVenue,
} from "@summtech/flok-core/models/rfp"
import {eventProfileToDates} from "@summtech/flok-core/utils"
import {useQueries, useQuery} from "@tanstack/react-query"
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {useHistory} from "react-router"
import * as timeago from "timeago.js"
import {useVenue} from "../components/VenueProvider"
import {AppRoutes} from "../Stack"

let MainBody = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "0px 20px 16px 20px",
  overflow: "auto",
})

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
  padding: "16px 0px",
  width: "100%",
})

const FlexApart = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
})

const DisplayedStatusValues: RFPStepType[] = [
  "PROPOSAL_REQUEST",
  "PROPOSAL_SUBMIT",
  "SHORTLIST_ADD",
  "CONCESSION_SUBMIT",
]

const Flex = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
})

const KanbanCard = styled("div", {
  padding: "12px",
  borderRadius: theme.shape.borderRadius,
  background: "$white",
  border: "1px solid $gray6",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})
const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})
export default function LeadsPage() {
  let [venue] = useVenue()
  let rfpStepsQuery = useQuery({
    queryKey: ["rfpSteps", venue.id],
    queryFn: () => getRFPSteps(venue.id),
  })
  let rfpSteps = rfpStepsQuery.data?.rfp_steps ?? []
  let rfpStepMap: {[eventId: number]: RFPStepModel[]} = {}
  rfpSteps.forEach((step) => {
    if (rfpStepMap[step.event_id]) {
      rfpStepMap[step.event_id].push(step)
    } else {
      rfpStepMap[step.event_id] = [step]
    }
  })

  let leads: RFPStatus[] = Object.values(rfpStepMap).map((steps) => {
    return new RFPStatus(steps)
  })
  let useTable = true
  let columnHelper = createColumnHelper<RFPStatus>()
  let eventsQueries = useQueries({
    queries: leads.map((lead) => ({
      queryKey: ["events", lead.getEventId()],
      queryFn: () => getEvent(lead.getEventId()),
    })),
  })
  let options: TableOptions<RFPStatus> = {
    defaultColumn: {
      enableGlobalFilter: false,
      size: undefined,
    },
    initialState: {
      sorting: [{id: "name", desc: false}],
    },
    columns: [
      columnHelper.accessor(
        (row) => {
          return eventsQueries.find((query) => {
            return query.isSuccess && query.data!.event.id === row.getEventId()
          })?.data?.event.name
        },
        {
          id: "name",
          header: "Name",
          size: 300,
          sortingFn: (a, b) => {
            return -1
          },
          cell: (context) => {
            return <Text variant={"text-sm-plus"}>{context.getValue()}</Text>
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.getActiveStep()
        },
        {
          id: "status",
          header: "Status",
          size: 300,
          sortingFn: (a, b) => {
            return -1
          },
          cell: (context) => {
            let activeStep = context.getValue() as RFPStepModel
            return (
              <Flex>
                <Text
                  variant={"text-sm-plus"}
                  css={{
                    color:
                      activeStep.type === "PROPOSAL_SUBMIT" ||
                      activeStep.type === "CONCESSION_SUBMIT"
                        ? "$gray12"
                        : "$yellow11",
                    padding: "12px 0px",
                  }}>
                  {/* @ts-ignore */}
                  {RFPStepTypeToTextForVenue[activeStep.type]}
                </Text>
                <Text css={{color: "$gray11"}} variant={"text-sm-plus"}>
                  ·
                </Text>
                <Text css={{color: "$gray11"}} variant={"text-sm-plus"}>
                  {timeago.format(new Date(activeStep.created_at))}
                </Text>
              </Flex>
            )
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.getEventId()
        },
        {
          id: "dates",
          header: "Dates",
          size: 300,

          cell: (context) => {
            return (
              <EventDates
                eventProfileId={context.getValue()}
                variant={"text-sm"}
                css={{color: "$gray12"}}
              />
            )
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.getEventId()
        },
        {
          id: "attendees",
          header: "Attendees",
          size: 300,

          cell: (context) => {
            return (
              <EventAttendees
                eventProfileId={context.getValue()}
                variant={"text-sm"}
                css={{color: "$gray12"}}
              />
            )
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.getAssigneeIds()
        },
        {
          id: "assignees",
          header: "Assignees",
          size: 300,

          cell: (context) => {
            return <EventAssignees assigneeIds={context.getValue()} />
          },
        }
      ),
    ],
    data: leads.filter((lead) =>
      DisplayedStatusValues.includes(lead.getActiveStep().type)
    ),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }
  const table = useReactTable(options)
  let history = useHistory()
  function renderRows(rows: Row<RFPStatus>[]) {
    return rows.map((row, i) => {
      let lastRow = rows[i - 1]
      let firstRow = true
      if (
        lastRow &&
        lastRow.original.getActiveStep() === row.original.getActiveStep()
      ) {
        firstRow = false
      }
      return (
        <>
          {firstRow && (
            <RowGroupHeader
              header={
                // @ts-ignore
                RFPStepTypeToTextForVenue[row.original.getActiveStep().type]
              }
              number={
                leads.filter(
                  (lead) =>
                    lead.getActiveStep() === row.original.getActiveStep()
                ).length
              }
            />
          )}
          <AppReactTable.Row
            key={row.original.getEventId()}
            row={row}
            onClick={() => {
              history.push(
                AppRoutes.getPath("LeadPage", {
                  eventId: row.original.getEventId().toString(),
                })
              )
            }}
          />
        </>
      )
    })
  }
  function renderAdditionalHeaders() {
    return DisplayedStatusValues.filter((status) => {
      return (
        leads.filter((lead) => lead.getActiveStep().type === status).length ===
        0
      )
    }).map((status) => {
      return (
        //  @ts-ignore
        <RowGroupHeader number={0} header={RFPStepTypeToTextForVenue[status]} />
      )
    })
  }
  return (
    <PageBody>
      <MainBody>
        <HeaderContainer>
          <FlexApart>
            <Text variant={"heading-lg"}>Leads</Text>
            <Button variant={"outline"} text="How It Works" />
          </FlexApart>
          <FlexApart>
            <SearchBar />
          </FlexApart>
        </HeaderContainer>
        {useTable ? (
          <AppReactTable>
            <AppReactTable.Header>
              <tr>
                {table
                  .getHeaderGroups()
                  .map((headerGroup) =>
                    headerGroup.headers
                      .filter((header) => header.depth === 1)
                      .map((header) => (
                        <AppReactTable.HeaderCell
                          header={header}
                          key={header.id}
                        />
                      ))
                  )}
              </tr>
            </AppReactTable.Header>
            <AppReactTable.Body>
              {renderRows(table.getRowModel().rows)}
              {renderAdditionalHeaders()}
            </AppReactTable.Body>
          </AppReactTable>
        ) : (
          <KanbanGroupDisplay
            data={leads}
            getGroupId={(lead) => lead.getActiveStep().type}
            getGroupHeader={(groupId) => {
              return RFPStepTypeToTextForVenue[
                groupId as keyof typeof RFPStepTypeToTextForVenue
              ]
            }}
            itemToCell={(item: RFPStatus) => {
              let eventName = eventsQueries.find((query) => {
                return (
                  query.isSuccess && query.data!.event.id === item.getEventId()
                )
              })?.data?.event.name
              let activeStep = item.getActiveStep()
              return (
                <KanbanCard>
                  <FlexColumn>
                    <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
                      {eventName}
                    </Text>
                    <Flex>
                      <Text
                        variant={"text-sm-plus"}
                        css={{
                          color:
                            activeStep.type === "PROPOSAL_SUBMIT" ||
                            activeStep.type === "CONCESSION_SUBMIT"
                              ? "$gray12"
                              : "$yellow11",
                          whiteSpace: "nowrap",
                        }}>
                        {/* @ts-ignore */}
                        {RFPStepTypeToTextForVenue[activeStep.type]}
                      </Text>
                      <Text css={{color: "$gray11"}} variant={"text-sm-plus"}>
                        ·
                      </Text>
                      <Text
                        css={{color: "$gray11", whiteSpace: "nowrap"}}
                        variant={"text-sm"}>
                        {timeago.format(new Date(activeStep.created_at))}
                      </Text>
                    </Flex>
                  </FlexColumn>
                  <FlexColumn>
                    <EventDates eventProfileId={1} css={{color: "$gray11"}} />
                    <EventAttendees
                      eventProfileId={1}
                      css={{color: "$gray11"}}
                      withText
                    />
                  </FlexColumn>
                  <Divider />
                  <EventAssignees assigneeIds={item.getAssigneeIds()} />
                </KanbanCard>
              )
            }}
          />
        )}
      </MainBody>
    </PageBody>
  )
}

const GroupHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "48px",
  borderBottom: "1px solid $gray6",
  background: "$gray2",
  gap: "4px",
  padding: "4px",
})
const CaretDown = styled(TriangleDownIcon, {
  color: "$gray11",
  marginRight: "2px",
})
function RowGroupHeader(props: {header: string; number: number}) {
  return (
    <tr>
      <td colSpan={1000}>
        <GroupHeader>
          <CaretDown />
          <Text variant={"text-sm-plus"}>{props.header}</Text>
          <Text variant={"text-sm-plus"} css={{color: "$gray11"}}>
            {`(${props.number})`}
          </Text>
        </GroupHeader>
      </td>
    </tr>
  )
}

function EventDates(
  props: {eventProfileId: number} & React.ComponentProps<typeof Text>
) {
  let {eventProfileId, ...textProps} = props
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  return (
    <Text {...textProps}>
      {eventProfileQuery.isSuccess &&
        eventProfileToDates(eventProfileQuery.data!.event_profile)}
    </Text>
  )
}

function EventAttendees(
  props: {eventProfileId: number; withText?: boolean} & React.ComponentProps<
    typeof Text
  >
) {
  let {eventProfileId, ...textProps} = props
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  return (
    <Text {...textProps}>
      {eventProfileQuery.data?.event_profile.event_num_guests}
      {props.withText && " attendees"}
    </Text>
  )
}

const SecondAvatar = styled(Avatar, {
  zIndex: 30,
  outline: "1px solid $white",
  marginLeft: "-6px",
})
const AssigneesContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})
const UnassignedAvatarContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
  background: "$gray2",
  border: "1px solid $gray6",
})
const GrayPerson = styled(PersonIcon, {
  color: "$gray11",
})

function UnassignedAvatar() {
  return (
    <UnassignedAvatarContainer>
      <GrayPerson />
    </UnassignedAvatarContainer>
  )
}

function EventAssignees(props: {assigneeIds: number[]}) {
  let assigneeQueries = useQueries({
    queries:
      props.assigneeIds.map((userId) => ({
        queryFn: () => getUser(userId),
        queryKey: ["users", userId],
      })) ?? [],
  })
  let userQuery = useQuery({
    queryKey: ["users", "home"],
    queryFn: () => getUserHome(),
  })
  let assigneeNames = assigneeQueries
    .filter((query) => query.isSuccess)
    .map((query) =>
      query.data!.user.id === userQuery.data?.user.id
        ? "You"
        : query.data!.user.first_name + " " + query.data!.user.last_name
    )
  return (
    <AssigneesContainer>
      {assigneeNames[0] ? (
        <Avatar label={assigneeNames[0][0]} round />
      ) : (
        <UnassignedAvatar />
      )}
      {assigneeNames[1] && <SecondAvatar label={assigneeNames[1][0]} round />}
      <Text
        variant={"text-sm-plus"}
        css={{
          color: assigneeNames.length === 0 ? "$gray11" : "$gray12",
          marginLeft: "6px",
        }}>
        {assigneeNames.length === 0
          ? "Unassigned"
          : assigneeNames.length === 1
          ? assigneeNames[0]
          : `${assigneeNames[0]}, + ${assigneeNames.length - 1}`}
      </Text>
    </AssigneesContainer>
  )
}
