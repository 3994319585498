import {
  createAxiosInstance,
  createQueryClient,
  setApi,
} from "@summtech/flok-core/api"
import Config from "@summtech/flok-core/config"
import {createBrowserHistory} from "history"

/**
 * SETUP CUSTOM CONFIG VALUES
 */
const CONFIG_KEYS = [
  // keys in string format, ensuring that the corresponding key is in the setup_config.sh file
  // ex:
  // "new_key_1",
  // "new_key_2",
] as const
type ConfigKey = (typeof CONFIG_KEYS)[number]
let config = new Config<ConfigKey>(process.env)
export default config

/**
 * SETUP API
 */

export let queryClient = createQueryClient()
// This sets up the floxAxios import to work properly
setApi(createAxiosInstance(config.get("server_base_url")))

/**
 * Setup history for react-router
 */
export const history = createBrowserHistory()
