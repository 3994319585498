import {CaretRightIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getEvent, getEventProfile} from "@summtech/flok-core/api/event"
import {postRFPStep} from "@summtech/flok-core/api/rfp"
import {getRFPSteps} from "@summtech/flok-core/api/venue"
import ConcessionModalButton from "@summtech/flok-core/components/buttons/ConcessionModalButton"
import ProposalModalButton from "@summtech/flok-core/components/buttons/ProposalModalButton"
import {Divider} from "@summtech/flok-core/components/common"
import {InfoLabelA} from "@summtech/flok-core/components/InfoLabels"
import {EventProfileDisplay} from "@summtech/flok-core/components/layout/EventProfileDisplay"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import RejectRFPModal from "@summtech/flok-core/components/modals/RejectRFPModal"
import StepperC from "@summtech/flok-core/components/progress/StepperC"
import {RFPPostStepType, RFPStatus} from "@summtech/flok-core/models/rfp"
import {dateFormat} from "@summtech/flok-core/utils"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useState} from "react"
import {useHistory, useRouteMatch} from "react-router-dom"
import * as timeAgo from "timeago.js"
import {LinkOrText} from "../components/LinkOrText"
import {useVenue} from "../components/VenueProvider"
import {AppRoutes} from "../Stack"

let MainBody = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "0px 0px 16px 0px",
  overflow: "auto",
})

let TopBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "0px 20px",
})
let InnerBody = styled("div", {
  padding: "24px 32px",
  display: "flex",
  flexDirection: "row",
  gap: "24px",
})
let InnerDiv = styled("div", {
  display: "flex",
  padding: "12px 16px",
  flexDirection: "column",
  gap: "16px",
  border: "1px solid $gray6",
  borderRadius: theme.shape.borderRadius,
})
let InnerDivLeft = styled(InnerDiv, {
  width: "100%",
  flex: 1,
  height: "fit-content",
})
let InnerDivRight = styled(InnerDiv, {
  width: "320px",
  height: "100%",
})
let HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  paddingTop: "16px",
})
let Flex = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
})

let CaretRight = styled(CaretRightIcon, {
  color: "$gray11",
})

const EventProfileContainer = styled("div", {
  padding: "24px 32px",
  borderTop: "1px solid $gray6",
})
export function LeadDetailPage() {
  let router = useRouteMatch<{
    eventId: string
  }>()
  let eventId = parseInt(router.params.eventId)
  let eventQuery = useQuery({
    queryFn: () => getEvent(eventId),
    queryKey: ["event", eventId],
  })
  let event = eventQuery.data?.event
  let [venue] = useVenue()
  let rfpStepsQuery = useQuery({
    queryFn: () => getRFPSteps(venue.id, event?.id),
    queryKey: ["rfpSteps", venue.id, event?.id],
  })
  let queryClient = useQueryClient()
  let status = new RFPStatus(rfpStepsQuery.data?.rfp_steps ?? [])
  let postRFPStepMutation = useMutation({
    mutationFn: (variables: RFPPostStepType) =>
      postRFPStep({
        event_id: status.getEventId(),
        venue_id: status.getVenueId(),
        ...variables,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["rfpSteps", venue.id, event?.id])
    },
  })
  let eventProfileQuery = useQuery({
    queryFn: () => getEventProfile(status.getEventProfileId() as number),
    queryKey: ["eventProfile", status.getEventProfileId()],
  })
  let eventProfile = eventProfileQuery.data?.event_profile
  let {path} = useRouteMatch()
  let isEventProfileTab = path === AppRoutes.getPath("LeadEventProfilePage")
  let history = useHistory()
  let [rejectModalOpen, setRejectModalOpen] = useState(false)
  if (!event || !rfpStepsQuery.data?.rfp_steps) {
    return null
  }
  return (
    <PageBody>
      <RejectRFPModal
        creator="VENUE"
        open={rejectModalOpen}
        onClose={() => {
          setRejectModalOpen(false)
        }}
        onSubmit={(rejectionId: number) => {
          postRFPStepMutation.mutate({
            rejection_id: rejectionId,
            type: "PROPOSAL_DENY",
          })
          setRejectModalOpen(false)
        }}
      />
      <MainBody>
        <TopBody>
          <HeaderContainer>
            <LinkOrText
              to={AppRoutes.getPath("LeadsPage")}
              variant={"heading-lg"}
              text={"Leads"}
            />
            <CaretRight />
            <Text variant={"heading-lg"} css={{color: "$gray12"}}>
              {event.name}
            </Text>
          </HeaderContainer>
          <TabsWrapper
            onValueChange={(val: string) => {
              if (val === "event-profile") {
                history.push(
                  AppRoutes.getPath("LeadEventProfilePage", {
                    eventId: eventId.toString(),
                  })
                )
              } else {
                history.push(
                  AppRoutes.getPath("LeadPage", {
                    eventId: eventId.toString(),
                  })
                )
              }
            }}
            value={isEventProfileTab ? "event-profile" : "overview"}>
            <TabsList>
              <Tab value="overview" text={"Overview"} />
              <Tab value="event-profile" text={"Event Profile"} />
            </TabsList>
          </TabsWrapper>
        </TopBody>
        <Divider />
        {!isEventProfileTab && (
          <InnerBody>
            <InnerDivLeft>
              <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
                Status
              </Text>
              <StepperC
                steps={[
                  {
                    label: status.hasSentProposal()
                      ? "Proposal Sent"
                      : "Proposal requested",
                    completed: status.hasSentProposal(),
                    subtitle: !status.hasSentProposal()
                      ? `Client requested proposal ${timeAgo.format(
                          new Date(status.getRequestedProposalDate()!)
                        )}`
                      : `Proposal sent ${timeAgo.format(
                          new Date(status.getSentProposalDate()!)
                        )}`,
                    button:
                      status.hasSentProposal() && status.getProposalId() ? (
                        <ProposalModalButton
                          proposalId={status.getProposalId()!}
                        />
                      ) : (
                        <Button
                          color="brand"
                          text="Respond to RFP"
                          onClick={() => {
                            history.push(
                              AppRoutes.getPath("LeadProposalPage", {
                                eventId: eventId.toString(),
                              })
                            )
                          }}
                        />
                      ),
                    rejected: status.isRejected() && !status.addedToShortlist(),
                  },
                  {
                    label: "Shortlist",
                    completed: status.addedToShortlist(),
                    rejected:
                      status.isRejected() && !status.submittedConcessions(),
                  },
                  {
                    label: "Concessions",
                    completed: status.submittedConcessions(),
                    subtitle: status.addedToShortlist()
                      ? !status.submittedConcessions()
                        ? `Client requested concessions ${timeAgo.format(
                            new Date(status.getShortlistAddedDate()!)
                          )}`
                        : `Concessions sent ${timeAgo.format(
                            new Date(status.getConcessionSubmittedDate()!)
                          )}`
                      : undefined,
                    button: status.addedToShortlist() ? (
                      status.submittedConcessions() ? (
                        <ConcessionModalButton
                          concessionId={status.getConcessionId()!}
                        />
                      ) : (
                        <Button
                          color="brand"
                          text="Send Concessions"
                          onClick={() => {
                            history.push(
                              AppRoutes.getPath("LeadConcessionPage", {
                                eventId: eventId.toString(),
                              })
                            )
                          }}
                        />
                      )
                    ) : undefined,
                    rejected:
                      status.isRejected() && !status.submittedConcessions(),
                  },
                  {
                    label: "Contract",
                    completed: false,
                    rejected: true,
                  },
                ]}
              />
              <Divider />
              <InfoLabelA
                label={{text: "Client"}}
                data={{text: event.name}}></InfoLabelA>
              <Flex>
                <InfoLabelA
                  css={{
                    width: "400px",
                  }}
                  label={{text: "Preferred Event Dates"}}
                  data={{
                    text:
                      eventProfile?.dates_arrival &&
                      eventProfile?.dates_departure
                        ? `${dateFormat(
                            new Date(eventProfile!.dates_arrival!)
                          )} - ${dateFormat(
                            new Date(eventProfile!.dates_departure!)
                          )}`
                        : "-",
                  }}></InfoLabelA>
                <InfoLabelA
                  label={{text: "Total Attendees"}}
                  data={{
                    text: eventProfile?.event_num_guests
                      ? eventProfile.event_num_guests.toString()
                      : "-",
                  }}></InfoLabelA>
              </Flex>
              <Button
                text="View Event Profile"
                variant={"outline"}
                onClick={() => {
                  history.push(
                    AppRoutes.getPath("LeadEventProfilePage", {
                      eventId: eventId.toString(),
                    })
                  )
                }}
              />
            </InnerDivLeft>
            <InnerDivRight>
              <FormField
                type="select"
                fullWidth
                label="Assigned To"
                onChange={() => {}}
                placeholder="Andrew Busel"
              />
              {/* <FormField
                type="textarea"
                fullWidth
                label="Notes"
                onChange={() => {}}
                placeholder="Add notes..."
              /> */}
              <Divider />
              <InfoLabelA
                label={{text: "Contact"}}
                data={{text: eventProfile?.contact_company_name ?? "-"}}
              />
              <InfoLabelA
                label={{text: "Email"}}
                data={{text: eventProfile?.contact_email ?? "-"}}
              />
              <InfoLabelA
                label={{text: "Phone"}}
                data={{text: eventProfile?.contact_phone ?? "-"}}
              />
              <Divider />
              {!status.isRejected() && (
                <Button
                  variant={"outline"}
                  onClick={() => {
                    setRejectModalOpen(true)
                  }}
                  color="critical"
                  text="Reject RFP"
                  css={{width: "100%", justifyContent: "center"}}
                />
              )}
            </InnerDivRight>
          </InnerBody>
        )}
        {isEventProfileTab && eventProfile && (
          <EventProfileContainer>
            <EventProfileDisplay eventProfile={eventProfile} hideStatus />
          </EventProfileContainer>
        )}
      </MainBody>
    </PageBody>
  )
}
