import {QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import {Router} from "react-router-dom"
import Stack from "./Stack"
import "./config"
import {history, queryClient} from "./config"

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Router history={history}>
        <Stack />
      </Router>
    </QueryClientProvider>
  )
}
