import {getVenue} from "@summtech/flok-core/api/venue"
import {VenueModel} from "@summtech/flok-core/models/venue"
import {useQuery} from "@tanstack/react-query"
import {createContext, PropsWithChildren, useContext, useState} from "react"

const VenueContext = createContext<
  {venue: VenueModel; setVenueId: (id: string) => void} | undefined
>(undefined)

export function useVenue() {
  const venueContext = useContext(VenueContext)
  if (venueContext === undefined) {
    throw Error("useVenue must be used within a VenueProvider component")
  }
  return [venueContext.venue, venueContext.setVenueId] as const
}

export default function VenueProvider(props: PropsWithChildren<{}>) {
  // getUserHome()
  // let user = useSelector((state: RootState) => state.user.user)
  // if (!user) {
  //   throw Error("event provider needs to be in a ProtectedRoute")
  // }

  let [venueId, setVenueId] = useState("ccea80cc-f85e-4819-8933-c1b7ce501adb")

  // useEffect(() => {
  //   setVenueId(user!.venue_ids[0])
  // }, [user])

  let venueQuery = useQuery({
    enabled: venueId != null,
    queryKey: ["venues", venueId],
    queryFn: () => getVenue(venueId!),
  })

  return venueQuery.status === "loading" ? (
    // <LoadingPage />
    <>loading</>
  ) : venueQuery.status === "error" ? (
    // <OopsPage />
    <>oops</>
  ) : (
    <VenueContext.Provider
      value={{venue: venueQuery.data.venue, setVenueId: setVenueId}}>
      {props.children}
    </VenueContext.Provider>
  )
}
