import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {Link} from "react-router-dom"

const StyledLink = styled(Link, {
  textDecoration: "none",
  color: "$gray12",
})

const StyledExternalLink = styled("a", {
  textDecoration: "none",
  color: "$gray12",
})

export type LinkOrTextProps = {
  to?: string
  href?: string
  text: string
  linkCss?: React.ComponentProps<typeof StyledLink>["css"]
} & React.ComponentProps<typeof Text>

export function LinkOrText(props: LinkOrTextProps) {
  let {...textProps} = {...props}
  return <></>
  // return to ? (
  //   <StyledLink to={to}>
  //     <Text {...textProps}>{props.text}</Text>
  //   </StyledLink>
  // ) : href ? (
  //   <StyledExternalLink href={href}>
  //     <Text {...textProps}>{props.text}</Text>
  //   </StyledExternalLink>
  // ) : (
  //   <Text {...textProps}>{props.text}</Text>
  // )
}
